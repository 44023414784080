import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layouts/Layout";
import LoginLayout from "./pages/Layouts/LoginLayout";
import NoPage from "./pages/NoPage/NoPage";

import Landing from "./pages/Landing/Landing";

import Login from "./pages/Login/Login";
import TwoFactorAuth from "./pages/Login/TwoFactorAuth";
import Register from "./pages/Register/Register";
import MailConfirm from "./pages/Register/MailConfirm";

import Home from "./pages/Home/Home";
import Messages from "./pages/Messages/Messages";
import Analysis from "./pages/Analysis/Analysis";
import Results from "./pages/Results/Results";
import Result from "./pages/Result/Result";
import Orders from "./pages/Orders/Orders";
import General from "./pages/Settings/General/General";
import RequestForm from "./pages/RequestForm/RequestForm";
import RequestFormNew from "./pages/RequestForm/RequestFormNew";

import Cookies from "js-cookie";


function App() {
  if (!Cookies.get("login")) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginLayout />}>
            <Route index element={<Landing />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="mailconfirm" element={<MailConfirm />} />
            <Route path="twofactor" element={<TwoFactorAuth />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="messages" element={<Messages />} />
          <Route path="analysis" element={<Analysis />} />
          <Route path="results" element={<Results />} />
          <Route path="results/:id" element={<Result />} />
          <Route path="orders" element={<Orders />} />
          <Route path="settings"  element={<General />}/>
          <Route path="request-form"  element={<RequestForm />}/>
          <Route path="request-form/new"  element={<RequestFormNew />}/>
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
