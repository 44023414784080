import React from "react";
import "./nopage.css"

const NoPage = () => {
  return (
    <>
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center row">
          <div class=" col-md-6">
            <h1 className="big-h1 text-blue-ocean-dark">404</h1>
          </div>
          <div class=" col-md-6 mt-5">
            <p class="fs-3">
              {" "}
              <span class="text-blue-ocean-dark">Opps! Page not found.</span>
            </p>
            <p class="lead">The page you’re looking for doesn’t exist.</p>
            <a href="/" class="btn btn-blue-ocean-dark">
              Go Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoPage;
