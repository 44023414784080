import React from 'react'
import Notice from '../../components/Notice/Notice'
import ServerStatus from '../../components/ServerStatus/ServerStatus'

const Home = () => {
  return (
    <>
    <div className="container my-4">
      <div className="row">
        <ServerStatus></ServerStatus>
      </div>
      <Notice></Notice>
    </div>
    </>
  )
}

export default Home