import React from "react";
import "./messages.css";
import MessageInfoCard from "../../components/Message/MessageInfoCard/MessageInfoCard";
import MessageCard from "../../components/Message/MessageCard/MessageCard";
import { useState } from "react";

const Messages = () => {
  const [status, setStatus] = useState("last");

  return (
    <>
      <div className="mt-5 px-5">
        <div className="row">
          <div className="col-lg-3">
            <div className="card p-2 bg-scove-light ">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <button
                    type="button"
                    className={
                      status === "last"
                        ? "btn btn-scove-light-100 text-scove-light-600"
                        : "nav-link text-white"
                    }
                    onClick={() => setStatus("last")}
                  >
                    Recent Chats
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    className={
                      status === "people"
                        ? "btn btn-scove-light-100 text-scove-light-600"
                        : "nav-link text-white"
                    }
                    onClick={() => setStatus("people")}
                  >
                    Persons
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="card p-2 bg-scove-light border-0">
              <span className="fs-4 text-white">Kadir From Technical Support</span>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-3" id="style-3">
            <div class="list-group message-wrap">
              <MessageInfoCard own={"current"}></MessageInfoCard>
              <MessageInfoCard></MessageInfoCard>
            </div>
          </div>
          <div className="col-lg-9">
            <div
              className="message-box-wrap border rounded bg-white"
              id="style-3"
            >
              <MessageCard own={true}></MessageCard>
              <MessageCard></MessageCard>
            </div>
            <div className="message-input-wrap">
              <div class="input-group">
                <textarea
                  type="text"
                  class="form-control bg-scove-light text-white border-0"
                  placeholder="Mesaj"
                  aria-label="Mesaj"
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-scove-dark"
                  type="button"
                  id="button-addon2"
                >
                  Gönder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
