import React, { useState } from "react";
import ResultsCard from "../../components/ResultsCard/ResultsCard";
import Cookies from "js-cookie";

const Results = () => {
  const [results, setResults] = useState([]);
  const [ loading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  // Get data from server
  const getData = async () => {
    const res = await fetch("https://scove-api.herokuapp.com/api/analysis/user/" + Cookies.get('id', '/'))
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return [];
      }
    }).then((data) => {
      setResults(data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  getData();


  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <div className="card border border-0 shadow my-3">
              <div className="card-body">
                <span className="fw-bold fs-2">Results and Reports</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card border border-0 shadow">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3">
                    <select
                      class="form-select border border-0 shadow  rounded-0"
                      aria-label="Default select example"
                    >
                      <option selected>Default Sort</option>
                      <option value="1">Most Recent First</option>
                      <option value="2">Most Distant Date First</option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <div class="input-group shadow mb-3">
                      <input
                        type="text"
                        class="form-control border border-0 rounded-0"
                        placeholder="Analysis ID Search"
                      />
                      <button
                        class="btn btn-outline-scove-dark border border-0 rounded-0"
                        type="button"
                      >
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3 ">
                  <div class="input-group shadow mb-3">
                      <input
                        type="text"
                        class="form-control border border-0 rounded-0"
                        placeholder="Folder Name Search"
                      />
                      <button
                        class="btn btn-outline-scove-dark border border-0 rounded-0"
                        type="button"
                      >
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                <div className="card border border-0 shadow rounded mb-1">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4 fw-bold text-center">Analysis ID</div>
                      <div className="col-3 fw-bold text-center">Folder Name</div>
                      <div className="col-2 fw-bold text-center">History</div>
                      <div className="col-1 fw-bold text-center">Situation</div>
                      <div className="col-2 fw-bold text-center"></div>
                    </div>
                  </div>
                </div>
                <div style={{overflow: "scroll", height: "55vh"}}>
                {
                  results.length > 0 ? results.map((result) => (
                    <ResultsCard
                      id={result._id}
                      filename={result.filename}
                      foldername={result.foldername}
                      status={result.resultStatus}
                      resultFilePath={result.resultFilePath}
                      resultFile={result.resultFilePath}
                      date={result.created_at}
                    />
                  )) : (
                    loading ? (
                      <div className="text-center">
                        <div className="spinner-border text-scove-dark" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      error ? (
                        <div className="text-center">
                          <span className="text-danger">Error fetching data</span>
                        </div>
                      ) : (
                        <div className="text-center mt-3">
                          <h3>No data found</h3>
                        </div>
                      )
                    )
                  )
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Results;
