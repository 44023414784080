import React from "react";
import { Link } from "react-router-dom";
import OrderListCard from "../../components/Order/OrderListCard/OrderListCard";

const Orders = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row pt-5">
          <div className="col-12">
            <OrderListCard></OrderListCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
