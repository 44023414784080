import React from "react";

const Notification = () => {
  return (
    <>
      <li>
        <a class="dropdown-item" href="#">
          <i class="fa-solid fa-triangle-exclamation text-danger"></i>
          {"  "}Attention!
          <p>
          As the server will be maintained, the system will be disabled on Wednesday Nov 9, at 19.00-23.00 UTC/GMT +3.
          </p>
        </a>
      </li>
      <li>
        <a class="dropdown-item" href="#">
          <i class="fa-solid fa-envelope text-primary"></i>
          {"  "}Message from Kadir Technical Support
          <p>
          Welcome to Scove Cloud. Would you like to share your experiences with us and help improve our system? Please email kadir@scove-systems.com. Your thoughts are valuable to us.
          <br /><br />
          Best wishes
          </p>
        </a>
      </li>
      {/* <li>
        <a class="dropdown-item" href="#">
          <i class="fa-solid fa-circle-check text-success"></i>
          {"  "}Analysis complete
        </a>
      </li>
      <li>
        <a class="dropdown-item" href="#">
          <div class="spinner-border spinner-border-sm text-warning " role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          {"  "}Analysis processed
        </a>
      </li> */}
    </>
  );
};

export default Notification;
