import Cookies from "js-cookie";
import React, { useState } from "react";
import AnalysisModal from "../../components/AnalysisCard/AnalysisModal";
import { useForm } from "react-hook-form";
import "./analysis.css";
import axios from "axios";

const Analysis = () => {
  const { register, handleSubmit} = useForm()
  const [dirName, setDirName] = useState(null)
  const [isUploaded, setUploaded] = useState(false)
  const [loadStatus, setLoadStatus] = useState(false)
  const [loadPercent, setLoadPercent] = useState(0)
  const [dataType, setDataType] = useState(null)
  const [err, setErr] = useState();

  /**
   * 
   * @param {*} e 
   */
  const onSubmit = async e => {
    const formData = new FormData();
    formData.append("file", e.file[0]);
    formData.append("dirName", dirName);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      }
    };

    setUploaded(false)
    setLoadStatus(true)

    try {
      // Upload file with axios and set upload progress 
      const res = await axios({
        method: "post",
        url: "https://scove-api.herokuapp.com/api/cloud/upload/" + Cookies.get('id', '/'),
        data: formData,
        config,
        // upload progress from server
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setLoadPercent(percent)
          }
        }
      }).then(res => {
        if (res.status === 200) {
          setUploaded(true)
          setLoadStatus(false)
        } else {
          setErr("Upload failed")
        }
        const analysis = res.data.analysis
        console.log(dataType)
        // new request to api for instruction
        axios("https://scove-api.herokuapp.com/api/instructions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            analysisId: analysis._id,
            dataType: {
              dataType1: dataType
            }
          })
        }).then(res => {
          if (res.status !== 200) {
            setErr("Instruction failed")
          }
        })
      }).catch(err => {
        console.log(err)
      })
      setUploaded(true)
    } catch (err) {
      console.log(err);
    } 
  }

  return (
    <>
      <>
        <div className="container mt-5">
          <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div class="card border-0 shadow-lg mb-4">
            <div class="card-body">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-scove-dark text-white"
                      id="basic-addon1"
                    >
                      <i class="fa-solid fa-folder"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="Enter the folder name..."
                      aria-describedby="button-addon2"
                      onChange={(e) => setDirName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <span>
                      To upload a file, select a file from your computer:
                      </span>
                    </div>
                    <div className="col-lg-6">
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <input 
                        className="form-control"
                        type="file"
                        {...register("file")}
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="row ">
                        <div className="col-12">
                          <button
                            className="w-100 btn btn-blue"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i class="fa-solid fa-plus"></i> Add Instruction
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Upload progress bar */}
          <div className="row">
            <div className="col-lg-12 mb-3">
              {
                isUploaded ? (
                  <div class="alert alert-success" role="alert">
                    File uploaded successfully!
                  </div>
                ) : (err ? (
                    <div class="alert alert-danger" role="alert">
                      {err}
                    </div>
                  ) : null 
                )
              }
              {
                loadStatus ? (
                  <div class="alert alert-scove-light text-center" role="alert">
                    File uploading, please wait!
                    <div class="spinner-border text-primary ms-5" role="status">
                    </div>
                    <div class="progress my-4">
                        <div
                          class="progress-bar bg-scove-dark progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuenow={loadPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${loadPercent}%` }}
                        >
                          {loadPercent}%
                        </div>
                      </div>
                  </div>
                ) : null
              }
              {
                isUploaded ? (
                  <button
                    className="btn btn-green text-white w-100"
                    type="submit"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                      <i class="fa-solid fa-rotate-right fs-1"></i>
                      </div>
                      <div className="col-lg-12">Send New File</div>
                    </div>
                  </button>
                ) : null
              }
              {
                !loadStatus && !isUploaded ? (
                  <button
                    className="btn btn-green text-white w-100"
                    type="submit"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <i class="fa-solid fa-check fs-1"></i>
                      </div>
                      <div className="col-lg-12">Confirm and Submit</div>
                    </div>
                  </button>
                ) : null
              }
            </div>
          </div>
          </form>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Instruction Form
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Add Data Types
                  </label>
                  <textarea
                    style={{ height: "400px" }}
                    class="form-control"
                    id="exampleFormControlInput1"
                    onChange={(e) => setDataType(e.target.value)}
                  />
                </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button class="btn btn-scove-light text-white" type="button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    </>
  );
};

export default Analysis;
