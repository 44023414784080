import React from "react";
import OrderListItem from "../OrderListItem/OrderListItem";
import "./orderListCard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";

const OrderListCard = () => {

  const {register, handleSubmit} = useForm();
  
  const [startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 30), 16));
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  const onSubmit = async () => {
    setLoading(true);
    const res = await fetch("https://scove-api.herokuapp.com/api/appointments/" + Cookies.get('id', '/'), {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        date: startDate,
        username: Cookies.get('username', '/'),
        status: "PENDING",
      }),
    }).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  useState(() => {
    setLoading(true);
    const res = fetch("https://scove-api.herokuapp.com/api/appointments/user/" + Cookies.get('id', '/'), {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }).then(res => {
      res.json().then(data => {
        console.log(data)
        setAppointments(data);
        setLoading(false);
      })
    }).catch(err => {
      console.log(err);
    })
  }, [])


  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card border border-0 shadow my-3">
            <div className="card-body">
              <span className="fw-bold fs-2">Appointments</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card border border-0 shadow card-max-wrap" id="style-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <select
                class="form-select border border-0 shadow  rounded-0"
                aria-label="Default select example"
              >
                <option selected>Default Sort</option>
                <option value="1">Most Recent Date First</option>
                <option value="2">Most Distant Date First</option>
              </select>
            </div>
            <div className="col-lg-3">
              <div class="input-group shadow mb-3">
                <input
                  type="text"
                  class="form-control border border-0 rounded-0"
                  placeholder="Search for an Appointment"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-outline-scove-dark border border-0 rounded-0"
                  type="button"
                  id="button-addon2"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-3 "></div>
            <div className="col-lg-3">
              <button
                className="w-100 btn btn-outline-scove-dark-600 border border-0 shadow fs-5  rounded-0"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <i className="fa-solid fa-calendar-plus"></i> Create an Appointment
              </button>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="card border border-0 shadow rounded mb-1">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-3 text-center fw-bold">ID</div>
                    <div className="col-lg-3 text-center fw-bold">Appointment Date</div>
                    <div className="col-lg-3 text-center fw-bold">Status</div>
                    <div className="col-lg-3 text-center fw-bold"></div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ( 
                    appointments ? (
                      appointments.map((appointment, index) => {
                        return (
                          <OrderListItem 
                            key={index}
                            index={index}
                            id={appointment._id}
                            date={appointment.date}
                            status={appointment.status}
                          />
                        )
                      }
                    )
                  ) : (
                      <div className="text-center">
                        <h1>No Appointments</h1>
                      </div>
                    )
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
              Create an Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
              <div class="modal-body">
                <div className="container">
                  <label for="date" class="form-label">
                        Appointment date
                        </label>
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-red-600" data-bs-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-scove-dark"> Request an Appointment</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderListCard;
