import React from "react";

const Notice = () => {
  return (
    <>
      {/* <div class="alert alert-warning mt-4 shadow" role="alert">
        <h4 class="alert-heading">Üzgünüz!</h4>
        <p>
          Herhangi bir duyuru bulunamadı. Sunucu ya da sistemle ilgili bir problem olduğu düşünüyorsanız destek ekibimze mail atabilir ya da arayabilirisiniz.
        </p>
        <hr />
        <p class="mb-0">
          Duyuru bulunamadı hatası. Bu kodu destek ekibine veriniz : (NTCx505)
        </p>
      </div> */}
      <div class="card border-danger border-0 mt-4 shadow">
        <div class="card-body">
          <h5 class="card-title">
          <i class="fa-solid fa-triangle-exclamation text-danger"></i>
          {"  "}Attention!
          </h5><hr />
          <p class="card-text">
          As the server will be maintained, the system will be disabled on Wednesday Nov 9, at 19.00-23.00 UTC/GMT +3.
          </p>
        </div>
      </div>
    </>
  );
};

export default Notice;
