import Cookies from "js-cookie";


async function login(email, password) {
  return await fetch("https://scove-api.herokuapp.com/api/auth/login", {
    // Cors options
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
  .then((response) => response.json())
  .then(data => {
      Cookies.set("login", true, { expires: 7, path: "/" });
      Cookies.set("id", data.user._id, { expires: 7, path: "/" });
      Cookies.set("username", data.user.name, { expires: 7, path: "/" });
      Cookies.set("email", data.user.email, { expires: 7, path: "/" });
      Cookies.set("role", data.user.role, { expires: 7, path: "/" });
      Cookies.set("instutity", data.user.intitution ? data.user.intitution : "" , { expires: 7, path: "/" });
      Cookies.set("phone", data.user.phone ? data.user.phone : "" , { expires: 7, path: "/" });

      if(data.user.profile_photo_path){
        Cookies.set("file_path", data.user.profile_photo_path, { expires: 7, path: "/" });
      }else{
        Cookies.set("file_path", "system/default/user.png", { expires: 7, path: "/" });
      }

      getProfilePicLink().then(res => {
        console.log(res)
        Cookies.set('profile_photo_path', res , { path: '/' });
      })
      
      window.location.href = "/";
      return true;
    }).catch((error) => {
      return console.log(error);
    });
}

async function getProfilePicLink(){
  return await fetch("https://scove-api.herokuapp.com/api/cloud/link", {
    // Cors options
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      filePath: Cookies.get("file_path"),
    }),
  })
  .then((response) => response.json())
}


// async function register(userName, email, password, title, institution, phone) {
//   return await fetch("http://api.scove-systems.com/users", {
//   // return await fetch("https://scove-cloud-api.herokuapp.com/api/v1/auth/register", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json;charset=utf-8",
//     },
//     body: JSON.stringify({
//       user_name: userName,
//       user_email: email,
//       user_password: password,
//     }),
//   })
//     .then((response) => {
//       console.log(response.status);

//       if (response.status === 201) {
//         return response.json();
//       } else return false;
//     })
//     .then((response) => {
//       if (response === false) {
//         return false;
//       }
//       console.log(response);
//       console.log(response._id);
//       Cookies.set("id", response._id, { expires: 7, path: "/" });
//       Cookies.set("username", response.username, { expires: 7, path: "/" });
//       Cookies.set("email", response.email, { expires: 7, path: "/" });
//       Cookies.set("role", response.isAdmin, { expires: 7, path: "/" });
//       return true;
//     });
// }

async function logout() {
  Cookies.remove("login", { path: "/" });
  Cookies.remove("id", { path: "/" });
  Cookies.remove("username", { path: "/" });
  Cookies.remove("email", { path: "/" });
  Cookies.remove("role", { path: "/" });
  Cookies.remove("instutity", { path: "/" });
  Cookies.remove("phone", { path: "/" });
  Cookies.remove("profile_photo_path", { path: "/" });
  window.location.href = "/";
}

export { login, logout, getProfilePicLink };
