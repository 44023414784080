import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { getProfilePicLink } from '../../../api/Auth';

const General = () => {
  const {register, handleSubmit} = useForm();
  const {register: register2, handleSubmit: handleSubmit2} = useForm();
  const [username, setUsername] = useState(Cookies.get('username', '/'));
  const [email, setEmail] = useState(Cookies.get('email', '/'));

  const [saveLoader, setSaveLoader] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);

  // Loaders
  const [ppLoader, setPpLoader] = useState(false);
  
  const onPpSubmit = async e => {
    const formData = new FormData();
    formData.append("file", e.file[0]);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      }
    };

    setPpLoader(true)

    try {
      const res = await axios({
        method: "post",
        url: "https://scove-api.herokuapp.com/api/cloud/upload/" + Cookies.get('id', '/') + "/profilePic",
        data: formData,
        config,
      }).then(res => {
        if (res.status === 200) {
          // Get new profile pic link
          getProfilePicLink(Cookies.get('id', '/')).then(res => {
            console.log(res)
            Cookies.set('profile_photo_path', res , { path: '/' });
            setPpLoader(false);
          })
        } else {
          console.log(res)
          setPpLoader(false)
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
      console.log(err);
    }
  }

  const onDetailSubmit = async e => {
    setSaveLoader(true);
    setSaveStatus(false);

    try {
      const res = await axios({
        method: "put",
        url: "https://scove-api.herokuapp.com/api/users/" + Cookies.get('id', '/'),
        data: {
          institution: e.institution,
          phone: e.phone,
          password: e.password
        }
      }).then(res => {
        if (res.status === 200) {
          setSaveLoader(false);
          setSaveStatus(true);
          Cookies.set('institution', e.institution, { path: '/' });
          Cookies.set('phone', e.phone, { path: '/' });
        } else {
          setSaveLoader(false);
          setSaveStatus(false);
        }
      }
      ).catch(err => {
        console.log(err)
      }
      )
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
          <div className="card border border-0 shadow my-3">
              <div className="card-body">
                <span className="fw-bold fs-2">Settings</span>
              </div>
            </div>
            <div className="card border-0 shadow mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card border border-0 shadow">
                      <div className="card-body">
                        <span className="fw-bold fs-5">General</span>
                      </div>  
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card border border-0 shadow mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* Profile Photo */}
                            {
                              ppLoader ? (
                                <div className="d-flex justify-content-center">
                                  <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-center">
                                  <img src={Cookies.get('profile_photo_path', '/')} className="img-fluid rounded-circle" style={{width: "150px", height: "150px"}} alt="profile" />
                                </div>
                              )
                            }
                            <form enctype="multipart/form-data" onSubmit={handleSubmit(onPpSubmit)} >
                              {/* Change Profile Photo */}
                              <div class="input-group mt-3">
                                <input 
                                  type="file" 
                                  class="form-control" 
                                  id="inputGroupFile04" 
                                  aria-describedby="inputGroupFileAddon04" 
                                  aria-label="Upload"
                                  {...register("file")}
                                  >

                                </input>
                                <button class="btn btn-outline-secondary" type="submit" id="inputGroupFileAddon04">Change</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card border border-0 shadow mt-3">
                      <div className="card-body">
                        <form enctype="multipart/form-data" onSubmit={handleSubmit2(onDetailSubmit)}>
                        <div className="row">
                          {/* Profile Settings Form */}
                          <div className="form">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="exampleInputEmail1" className="form-label">Name & Surname</label>
                                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={username} readOnly/>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="exampleInputEmail1" className="form-label">Email</label>
                                  <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} readOnly/>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="exampleInputEmail1" className="form-label">Password</label>
                                  <input type="password" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register2("password")} />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="exampleInputEmail1" className="form-label">Phone Number</label>
                                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register2("phone")} />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label for="exampleInputEmail1" className="form-label">Intutities</label>
                                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register2("instutity")} />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Profile Settings Form */}
                        </div>
                        {/* Submit */}
                        {
                          saveLoader ? (
                            <button className='btn btn-success w-100' disabled> 
                              <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          ) : (
                            saveStatus ? (
                              <button className='btn btn-success w-100' type='submit'> Saved </button>
                            ) : (
                              <button className='btn btn-success w-100' type='submit'> Save </button>
                            )
                          )
                        }
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default General