import React from "react";

const OrderListItem = (props) => {

  const [status, setStatus] = React.useState(props.status);
  const [loading, setLoading] = React.useState(false);

  const handleStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch("https://scove-api.herokuapp.com/api/appointments/" + props.id, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        status: e.target.value,
      }),
    }).then((res) => {
      if (res.status === 200) {
        setStatus(e.target.value);
        setLoading(false);
      }
    }).catch((err) => {
      console.log(err);
    });
  };


  return (
    <>
      <div className="card border border-0 shadow rounded mb-1">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 fw-bold text-center text-truncate">{props.id}</div>
            {/* Rewrite date for clean reading */}
            <div className="col-lg-3 text-center text-truncate">
              {new Date(props.date).toLocaleDateString()}
            </div>
            {props.status === "PENDING" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-circle-pause fs-3 text-orange"></i></div> : ""}
            {props.status === "CANCELED" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-circle-xmark fs-3 text-red"></i></div> : ""}
            {props.status === "FINISHED" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-circle-check fs-3 text-green"></i></div> : ""}
            <div className="col-lg-3 text-center">
              <button className="btn btn-danger w-100" onClick={handleStatus}>
                <i class="fa-solid fa-trash"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderListItem;
