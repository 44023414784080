import React from "react";
import "./serverstatus.css";

const ServerStatus = () => {
  return (
    <>
      <div className="col-lg-6">
        <div className="card border-0 mt-4 shadow">
          <div className="card-body">
            <h3>System Status</h3>
            <ul class="list-group">
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">Website</button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-green-600 w-100">Online</button>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">Video Sharing Platform</button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-green-600 w-100">Online</button>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">Server Connection</button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-green-600 w-100">Online</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card border-0 mt-4 shadow">
          <div className="card-body">
            <h3>Region Status</h3>
            <ul class="list-group">
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">Server-Istanbul (Turkey)</button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-green-600 w-100">Online</button>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">Server-Frankfurt (Europe)</button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-red-700 w-100">Offline</button>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div className="row">
                  <div className="col-lg-6">
                    <button className="btn">
                      Server-US-West (United States)
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <button className="btn btn-red-700 w-100">Offline</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerStatus;
