import React from "react";
import { Outlet } from "react-router-dom";
import "./layout.css";

import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";

const Layout = () => {
  return (
    <>
      <div className="h-100">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-2 col-md-6 bg-scove-dark-600 p-0 m-0 h-100 rounded-end shadow-lg">
              <Sidebar></Sidebar>
            </div>
            <div className="col-lg-10 col-md-6 m-0 p-0">
              <Navbar></Navbar>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
