import React from "react";

const MessageInfoCard = (props) => {
  if (props.own === "current") {
    return (
      <>
        <button class="list-group-item list-group-item-action bg-scove-light text-white">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">Kadir From Technical Support</h5>
          </div>
          <small class=" text-white">Online</small>
          <p class="mb-1 text-truncate">
            Hello, I had a question about a topic.
          </p>
        </button>
      </>
    );
  }

  return (
    <>
      <button class="list-group-item list-group-item-action bg-scove-light-100">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">John From Technical Support</h5>
        </div>
        <small class="text-muted">Last Seen: Yesterday 14.04</small>
        <p class="mb-1 text-truncate">Hello, I do not understand a place on the analysis submission page, can you help?</p>
      </button>
    </>
  );
};

export default MessageInfoCard;
