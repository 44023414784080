import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";

const Sidebar = () => {
  const [menu, setMenu] = useState("dashboard");
  const [submenu, setSubMenu] = useState(null);

  function menuSetter(value, subValue = null) {
    setMenu(value);
    setSubMenu(subValue);
  }

  return (
    <>
      <div className="row p-0 m-0">
        <Link
          class="navbar-brand fs-1 bg-scove-dark-600 text-white"
          to="/"
        >
          <img
            className="img-fluid mx-4 my-2"
            src="http://scove-systems.com/media-placeholder/scove-cloud-light-bg-transparent-8342x2613-1.png"
            alt="Scove Cloud"
            style={{"max-height": "77px"}}
          />
        </Link>
      </div>
      <div className="px-2 py-0">
        <div className="card text-center text-white bg-transparent border-0 pt-3">
          <img
            src={
              Cookies.get("profile_photo_path", "/")
            }
            class="rounded-circle mx-auto d-block profile-pic card-img-top bg-white"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">{Cookies.get('username', '/')}</h5>
          </div>
        </div>
        <hr className="text-white" />
        <Link
          to="/"
          className={
            menu === "dashboard"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("dashboard")}
        >
          <i class="fa-solid fa-bullhorn fs-3"></i>{" "}
          <span className="ms-3">Announcements</span>
        </Link>
        {/* <Link
          to="/messages"
          className={
            menu === "messages"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("messages")}
        >
          <i class="fa-solid fa-envelope fs-3"></i>{" "}
          <span className="ms-3">Messages</span>
        </Link> */}
        <Link
          to="/analysis"
          className={
            menu === "analysis"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("analysis")}
        >
          <i class="fa-solid fa-magnifying-glass-chart fs-3"></i>{" "}
          <span className="ms-3">Submit Analysis</span>
        </Link>
        <Link
          to="/orders"
          className={
            menu === "orders"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("orders")}
        >
          <i class="fa-solid fa-calendar-day fs-3"></i>{" "}
          <span className="ms-3">Appointments</span>
        </Link>
        <Link
          to="/results"
          className={
            menu === "results"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("results")}
        >
          <i class="fa-solid fa-square-poll-horizontal fs-3"></i>{" "}
          <span className="ms-3">Results</span>
        </Link>
        <Link
          to="/request-form"
          className={
            menu === "request-form"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("request-form")}
        >
          <i class="fa-solid fa-envelope-open-text fs-3"></i>{" "}
          <span className="ms-3">Request Form</span>
        </Link>
        <Link
          to="/settings"
          className={
            menu === "settings"
              ? "btn btn-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
              : "btn btn-outline-scove-light border-0 scale-up-hor-center text-white text-start w-100 mt-2 fw-bold fs-5"
          }
          onClick={() => menuSetter("settings")}
        >
          <i class="fa-solid fa-gear fs-3"></i>{" "}
          <span className="ms-3">Settings</span>
        </Link>
      </div>
    </>
  );
};

export default Sidebar;
