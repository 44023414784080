import React from 'react'
import { Link } from 'react-router-dom';

const RequestFormItem = (props) => {
    return (  
        <>
          <div className="card border border-0 shadow rounded mb-1">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 text-center text-truncate">{props.head}</div>
                <div className="col-lg-3 text-center text-truncate text-danger">ADMIN</div>
                {/* Rewrite date for clean reading */}
                <div className="col-lg-3 text-center text-truncate">
                  {new Date(props.date).toLocaleDateString()}
                </div>
                {props.status === "PENDING" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-circle-pause fs-3 text-orange" ></i></div> : ""}
                {props.status === "OPEN" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-envelope-open-text fs-3 text-success"></i></div> : ""}
                {props.status === "CANCELED" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-circle-xmark fs-3 text-red"></i></div> : ""}
                {props.status === "CLOSED" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-circle-check fs-3 text-green"></i></div> : ""}
                <div className="col-lg-2 text-center"> <Link to={"/request-form/" + props.id} className='btn btn-primary'> <i className='fa fa-circle-info'></i> Details </Link> </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default RequestFormItem