import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css"

import Notification from "../Notification/Notification";
import {logout} from "../../api/Auth";
import Cookies from "js-cookie";

const Navbar = () => {

  function handleLogout() {
    logout().then(r => {
      window.location.reload();
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-scove-dark-600 nav-part-2 border border-scove-dark-600">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <Link
                class="nav-link dropdown-toggle ms-4"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-bell"></i>
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Notification></Notification>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <Link
                class="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {Cookies.get('username', '/')}
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link class="dropdown-item" to="#">
                    <i className="fa-solid fa-circle-user fs-5"></i>{" "}
                    Profile
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="#">
                    <i className="fa-solid fa-gear fs-5"></i> Settings
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleLogout()}>
                    <i className="fa-solid fa-right-from-bracket fs-5"></i>{" "}
                    Log out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
