import React from "react";

const Landing = () => {
  return (
    <>
      <div className="container">
        <div className="m-5 p-5">
          <center>
            <img className="img-fluid mb-5 p-5 bg-scove-dark" src="http://scove-systems.com/media-placeholder/scove-cloud-light-bg-transparent-8342x2613-1.png" alt="" />
            <span className="display-6 me-3 align-bottom">
              Go To Login Page
            </span>
            <a href="/login" className="btn btn-outline-scove-dark border border-3 border-scove-dark">
              <i class="fa-solid fa-arrow-right-long display-6"></i>
            </a>
          </center>
        </div>
      </div>
    </>
  );
};

export default Landing;
