import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const RequestFormNew = () => {

    const [head, setHead] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)
        // using fetch
        fetch('https://scove-api.herokuapp.com/api/form', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                head: head,
                message: message,
                userId: Cookies.get('id', '/')
            })
        }).then(() => {
            setLoading(false)
            window.location.href = '/request-form'
        }).catch((err) => {
            console.log(err)
        })
    }

  return (
    <>
        <div className="container my-5">
            <div className="row">
                <div className="col-12">
                    <div className="card border border-0 shadow my-3">
                        <div className="card-body">
                            <span className="fw-bold fs-2">New Request Form</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-3"></div>
                                <div className="col-lg-6">
                                    <div className="">
                                        <div className="mt-5">
                                            <div className="card border border-0 shadow">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <form onSubmit={
                                                            (e) => {
                                                                e.preventDefault()
                                                                onClick()
                                                            }
                                                        }>
                                                            <div class="mb-4">
                                                                <label for="formGroupExampleInput" class="form-label">Message Title</label>
                                                                <input type="text" class="form-control border-0 shadow" id="formGroupExampleInput" onChange={(e) => {setHead(e.target.value)}} />
                                                            </div>
                                                            <div class="mb-4">
                                                                <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                                                <textarea class="form-control border-0 shadow" id="exampleFormControlTextarea1" rows="5" onChange={(e) => {setMessage(e.target.value)}}></textarea>
                                                            </div>
                                                            {/* Submit */}
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <Link to="/request-form" className="btn btn-outline-scove-dark border-0 shadow w-100">
                                                                        <i className="fa fa-arrow-left"></i>  Back
                                                                    </Link>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <button className="btn btn-outline-scove-dark border-0 shadow w-100" type='submit' >
                                                                        {
                                                                            loading ? (
                                                                                <>
                                                                                    <i class="fa-solid fa-spinner fa-spin"></i> Send
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                <i className="fa fa-paper-plane"></i>  Send
                                                                                </>
                                                                            )
                                                                        }
                                                                        
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RequestFormNew