import React from "react";

const TwoFactorAuth = () => {
  return (
    <>
      <div class="mail-wrap">
        <div class="card p-5 border-0 rounded shadow">
          <div class="card-body">
            <h1 class="text-center text-dark mb-4">Scove</h1>
            <p className="text-center fw-bold">
              Giriş yapmak için son bir adım daha kaldı.
            </p>
            <div class="mb-3 row">
              <label
                for="inputPassword"
                class="col-sm-3 col-form-label card-subtitle mb-2 text-muted"
              >
                E-posta ile gelen onay kodunu giriniz
              </label>
              <div class="col-sm-9">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="XXXX-XXXX-XXXX"
                    aria-describedby="button-addon2"
                  />
                  <button class="btn btn-dark" type="button" id="button-addon2">
                    Onayla
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <center>
                <div className="col-12 mb-3">E-posta gelmedi mi?</div>
                <div className="col-12">
                  <button className="btn btn-dark">Tekrar Gönder</button>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
