import React from "react";
import "./messageCard.css";

const MessageCard = (props) => {
  if (props.own === true) {
    return (
      <>
        <div className="card col-6 m-3 border-0 float-end bg-transparent">
          <div className="text-white shadow bg-scove-light border-0 rounded px-4 py-2">
            <div className="row">
              <p>Hello, I had a question about a topic.</p>
            </div>
          </div>
          <span className="text-muted">15 minutes ago </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card col-6 m-3 border-0 bg-transparent">
        <div className=" border-0 bg-scove-light-100 shadow text-dark rounded px-4 py-2">
          <p>Sure, let's help</p>
        </div>
        <span className="text-muted">Now</span>
      </div>
    </>
  );
};

export default MessageCard;
