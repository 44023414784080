import React from "react";
import { useParams } from "react-router-dom";

const Result = () => {
  let { id } = useParams();

  

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <div className="card border border-0 shadow my-3">
              <div className="card-body">
                <span className="fw-bold fs-2">Analysis ID : {id}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card border border-0 shadow">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card col-lg-12">
                      <div className="card-body">
                        <h5 className="card-title">Result File</h5>
                          {/* Download Buttun with file size and ext */}
                          <div className="row">
                            <div className="col-lg-6">
                              <a href="#" className="btn btn-primary w-100">
                                {" "} Result_023842048.pdf <br />
                                <i className="fas fa-download fs-4 mt-1"></i> <span className="fs-5 mt-1">Download</span>
                              </a>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
