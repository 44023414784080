import React from "react";
import "./resultCard.css"
import Cookies from "js-cookie";
import { useState } from "react";

const ResultsCard = (props) => {

  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageChange, setPageChange] = useState(false)

  const folderNameSplit = props.foldername.split('/')
  const folderName = folderNameSplit[1]

  const getFileLinks = async () => {
    setPageChange(true)
    setLoading(true);
    // using fetch from api
    await fetch("https://scove-api.herokuapp.com/api/cloud/link/analysis/report/" + props.foldername, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        // Return array
        return res.json();
      } else {
        return [];
      }
    }).then((data) => {
      console.log(data.links)
      setLinks(data.links);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  if (pageChange === false) {
    return (  
      <>
        <div className="card border border-0 shadow rounded mb-1">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 text-center fw-bold">{props.id}</div>
              <div className="col-lg-3 text-center text-truncate">{props.filename}</div>
              {/* Rewrite date for clean reading */}
              <div className="col-lg-2 text-center text-truncate">
                {new Date(props.date).toLocaleDateString()}
              </div>
              {props.status === "PENDING" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-sync fa-spin fs-3 text-orange" ></i></div> : ""}
              {props.status === "PROCESSING" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-gear fa-spin fs-3 text-success"></i></div> : ""}
              {props.status === "CANCELED" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-circle-xmark fs-3 text-red"></i></div> : ""}
              {props.status === "FINISHED" ? <div className="col-lg-1 text-center"><i class="fa-solid fa-circle-check fs-3 text-green"></i></div> : ""}
              {
                props.status === "FINISHED" ? (
                  <div className="col-lg-2 text-center">
                    <button 
                      className="btn btn-blue w-100" 
                      onClick={
                        () => {
                          getFileLinks();
                        }
                      } >
                      <i class="fa-solid fa-download"></i> Download
                    </button>
                  </div>
                ) : (
                  <div className="col-lg-2 text-center"> </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    );
  } else { 
    return (
      <>
        <div className="card border border-0 shadow rounded mb-1">
          <div className="card-body">
            <div className="row">
              {
                loading ? (
                  <div className="col-lg-12 text-center">
                    <i class="fa-solid fa-sync fa-spin fs-3 text-orange" ></i>
                  </div>
                ) : (
                  <div className="col-lg-12 text-center">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h3 className="text-blue-ocean-dark">Download Links</h3>
                      </div>
                      <div className="col-lg-12 text-center">
                        <p className="text-blue-ocean-dark">Click on the links below to download the files</p>
                      </div>
                      <div className="col-lg-12 text-center">
                        <div className="row">
                          {
                            Array.isArray(links) && links.length > 0 ? (
                              links.map((link, index) => {
                                return (
                                  <div className="col-lg-3 text-center mb-3">
                                    <a href={link} className="btn btn-outline-scove-dark border-0 shadow w-100" download>
                                      <i class="fa-solid fa-download"></i> File {index + 1}
                                    </a>
                                  </div>
                                )
                              })
                            ) : (
                              <div className="col-lg-12 text-center">
                                <p className="text-blue-ocean-dark">No links available</p>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      <div className="col-lg-12 text-center">
                        <button className="btn btn-outline-scove-dark border-0 shadow" onClick={() => {setPageChange(false)}}><i className="fa fa-arrow-left"></i>  Back</button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ResultsCard;