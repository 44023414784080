import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RequestFormItem from '../../components/RequestForm/RequestFormItem'

const RequestForm = () => {

    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getRequests = async () => {
            // using fetch from api
            const res = await fetch('https://scove-api.herokuapp.com/api/form/user/' + Cookies.get('id', '/'))
            const data = await res.json()
            setRequests(data)
            setLoading(false)
        }
        getRequests()
    }, [])



  return (
    <>
        <div className="container my-5">
            <div className="row">
                <div className="col-12">
                    <div className="card border border-0 shadow my-3">
                        <div className="card-body">
                            <span className="fw-bold fs-2">Request Form</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card border border-0 shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card border-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-9">
                                                    <h4>My Tickets</h4>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Link to="/request-form/new" className="btn btn-outline-scove-dark border-0 shadow w-100">
                                                        <i className="fa fa-plus"></i>  New Ticket
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{height: "55vh", overflowY: "scroll"}}>
                                    <div className="card border border-0 shadow rounded mb-1">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-3 fw-bold text-center">Ticket Head</div>
                                                <div className="col-3 fw-bold text-center">To</div>
                                                <div className="col-3 fw-bold text-center">Date</div>
                                                <div className="col-1 fw-bold text-center">Situation</div>
                                                <div className="col-2 fw-bold text-center"></div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        loading ? (
                                            <div className="card border border-0 shadow rounded mb-1">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 text-center">
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            requests.map((request) => (
                                                <RequestFormItem 
                                                    key={request._id}
                                                    head={request.head}
                                                    date={request.created_at}
                                                    status={request.status}
                                                    id={request._id}
                                                />
                                            ))    
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RequestForm